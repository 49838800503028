<template>
    <div class="content-container relative">
      <sensors-map class="m-0 p-0 mapcomponent" style="width: 100%; height: 100%; position:absolute" />
    </div>
</template>

<script>

import { watch, onMounted } from  "vue";
import { useStore } from "vuex";

export default ({
    name: "Karte",
    components: {  },
    setup() {
        let m_componentMounted = false;
        let m_componentInitialized = false;
        const store = useStore();

        watch(() => store.getters.isready, function () { initWhenReady(); });
        watch(() => store.getters.sensors, function () { initWhenReady(); });
        watch(() => store.getters.myinstallation, function () { initSensors(); });

        onMounted(() => {
            m_componentMounted = true;
            initWhenReady();
        });

        const initWhenReady = async () => {
            if (m_componentInitialized || !m_componentMounted || !store.getters.isready) return;
            m_componentInitialized = true;

            initSensors();
        }

        const initSensors = () => {
            store.dispatch("requireSensors");
        }

        return { 

        }
    },
})
</script>

<style scoped>

.mapcomponent {
  min-height: 0;    /* Let the content overflow */
  flex: 1;          /* Fill the available space */
}

</style>

